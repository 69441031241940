(function () {
  const inputs = document.querySelectorAll('.input-field');
  const fisrtName = document.getElementsByName('f_item_name_first')[0];
  const lastName = document.getElementsByName('f_item_name_last')[0];
  const nameErrMsg =
    location.pathname === '/'
      ? document.querySelector('.error-msg.name')
      : document.querySelector('.form__list__item__error-msg.name');
  const mailErrMsg =
    location.pathname === '/'
      ? document.querySelector('.error-msg.email')
      : document.querySelector('.form__list__item__error-msg.email');
  const telErrMsg =
    location.pathname === '/'
      ? document.querySelector('.error-msg.tel')
      : document.querySelector('.form__list__item__error-msg.tel');
  const textareaErrMsg = document.querySelector('.form__list__item__error-msg.textarea');
  const checkboxErrMsg =
    location.pathname === '/'
      ? document.querySelector('.error-msg.checkbox')
      : document.querySelector('.form__list__item__error-msg.checkbox');
  const submit = ['/', '/lp/'].includes(location.pathname)
    ? document.querySelector('.submit-btn')
    : document.querySelector('.form__submit__btn');
  const pattern = {
    name: /[^\s]+/,
    mail: /^([a-z0-9+_-]+)(.[a-z0-9+_-]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/i,
    tel: /^0[0-9]0-[0-9]{4}-[0-9]{4}$|^0[0-9]{10}$|^0[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$|^0[0-9]{9}$/,
    textarea: /[^\s]+/,
  };
  const regex = {
    name: new RegExp(pattern.name),
    mail: new RegExp(pattern.mail),
    tel: new RegExp(pattern.tel),
    textarea: new RegExp(pattern.textarea),
  };
  const validation = {
    name: false,
    mail: false,
    tel: false,
    textarea: false,
    checkbox: false,
  };
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('change', (event) => {
      const inputName = event.target.name;
      const input = inputs[i];
      handleSwitchInput(inputName, input);
    });
  }
  const handleSwitchInput = (inputName, input) => {
    switch (inputName) {
      case 'f_item_name_last':
      case 'f_item_name_first':
        if (validateCheck(regex.name, 'name')) {
          validation.name = true;
          correctInputValue('name', nameErrMsg);
        } else {
          validation.name = false;
          incorrectInputValue('name', nameErrMsg);
        }
        break;
      case 'f_item_mail':
        if (validateCheck(regex.mail, input)) {
          validation.mail = true;
          correctInputValue(input, mailErrMsg);
        } else {
          validation.mail = false;
          incorrectInputValue(input, mailErrMsg);
        }
        break;
      case 'f_item_tel':
        if (validateCheck(regex.tel, input)) {
          validation.tel = true;
          correctInputValue(input, telErrMsg);
        } else {
          validation.tel = false;
          incorrectInputValue(input, telErrMsg);
        }
        break;
      case 'f_item_free1':
        if (validateCheck(regex.textarea, input)) {
          validation.textarea = true;
          correctInputValue(input, textareaErrMsg);
        } else {
          validation.textarea = false;
          incorrectInputValue(input, textareaErrMsg);
        }
        break;
      case 'f_item_checkbox':
        if (input.checked) {
          validation.checkbox = true;
          correctInputValue(input, checkboxErrMsg);
        } else {
          validation.checkbox = false;
          incorrectInputValue(input, checkboxErrMsg);
        }
        break;
    }
  };
  const FilledFormChecker = () => {
    return location.pathname === '/contact/'
      ? validation.name && validation.mail && validation.tel && validation.textarea && validation.checkbox
      : validation.name && validation.mail && validation.tel && validation.checkbox;
  };
  submit.addEventListener('click', function (e) {
    if (!FilledFormChecker()) {
      e.preventDefault();
      for (let i = 0; i < inputs.length; i++) {
        const inputName = inputs[i].getAttribute('name');
        const input = inputs[i];
        handleSwitchInput(inputName, input);
      }
    }
  });
  const validateCheck = (targetRegex, inputField) => {
    return inputField === 'name'
      ? targetRegex.test(fisrtName.value) && targetRegex.test(lastName.value)
      : targetRegex.test(inputField.value);
  };
  const correctInputValue = (inputField, ErrMsg) => {
    if (inputField === 'name') {
      lastName.classList.remove('invalid');
      fisrtName.classList.remove('invalid');
    } else {
      inputField.classList.remove('invalid');
    }
    ErrMsg.classList.remove('visible');
  };
  const incorrectInputValue = (inputField, ErrMsg) => {
    if (inputField === 'name') {
      lastName.classList.add('invalid');
      fisrtName.classList.add('invalid');
    } else {
      inputField.classList.add('invalid');
    }
    ErrMsg.classList.add('visible');
  };
})();
